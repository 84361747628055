import {useRef} from 'react';
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/images/banners/banner_01.webp";
import bannerImage2 from "../../assets/images/banners/banner_02.webp";
import fbIcon from "../../assets/images/facebook_gray_icon.svg";
import linkedin from "../../assets/images/linkedin.png";
import instaIcon from "../../assets/images/instagram_gray_icon.svg";
import TwterIcon from "../../assets/images/twitter_twitter_icon.svg";
import Slider from "react-slick";

function Banner() {

  const settings = {
    dots: false,
    infinite: true,
    speed:1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    pauseOnFocus: false,
    pauseOnHover: false
  };


  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
    <section className={Styles.banner}>  
        <div className={Styles.bannerSocial}>
        <a href="https://www.linkedin.com/company/www.hostec.com/about/" target="_blank"><img src={linkedin} alt="Linkedin"/></a>
            <a href="https://www.facebook.com/profile.php?id=100085677016767" target="_blank"><img src={fbIcon} alt="Facebook"/></a>
            <a href="https://www.instagram.com/hostec.me/"  target="_blank"><img src={instaIcon} alt="Instagram"/></a>
            {/* <Link to="/"  target="_blank"><img src={TwterIcon} alt="Twitter"/></Link> */}
        </div>
        <section className="slider-wrapper">
            <Slider {...settings}>
              <div className={Styles.sliderItem}>
                <img src={bannerImage} className={Styles.bannerImage} alt="hostec"/>
                <div className={Styles.slidertextWrap}>
                  <div className="container">
                    <div className={Styles.slidertext}>
                      
                      <h1>
                       
                        <span>Welcome to</span>
                        <div className="typewriter">hostec</div>
                      </h1>
                      <div className={Styles.buttonWrap}>
                        <a href='/#/about' className="btn btn-primary">EXPLORE MORE</a>
                        <a target='_blank' href="https://portal.mysterydelegates.com/auth/index.asp#login" className="btn btn-secondary">Sign up</a>
                      </div>
                    </div>
                  </div>
                </div>
                <span className={Styles.count}>01</span>
              </div>
              <div className={Styles.sliderItem}>
                <img src={bannerImage2} className={Styles.bannerImage} alt="hostec"/>
                <div className={Styles.slidertextWrap}>
                  <div className="container">
                    <div className={Styles.slidertext2}>
                      <h1>
                        <span>Foster your </span>
                        <div className="typewriter2">service</div>
                      </h1>
                      <div className={Styles.buttonWrap1}>
                        <a href='/#/about' className="btn btn-primary">EXPLORE MORE</a>
                        <a target='_blank' href="https://portal.mysterydelegates.com/auth/index.asp#login" className="btn btn-secondary">Sign up</a>
                      </div>
                    </div>
                  </div>
                </div>
                <span className={Styles.count}>02</span>
              </div>
            </Slider> 
        </section>
        <div className={Styles.downArrow} onClick={handleClick}>Scroll Down</div>
    </section>
    <div ref={ref} className={Styles.scrollIntoView}>Hostec UAE</div>
    </>
  );
}

export default Banner;
