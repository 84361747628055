import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "./../../components/innerbanner";
import BeCreative from "./../../components/becreative";
import Servicesimg from "./../../assets/images/portfolio/crownePlazaDubaiMarina.jpg";
import Servicesimg2 from "./../../assets/images/portfolio/intercontinentalHotelAbuDhabi.jpg";
import Servicesimg3 from "./../../assets/images/portfolio/intercontinentalDubaiFestivalCity.jpg";
import Servicesimg4 from "./../../assets/images/portfolio/crownePlazaDubaiFestivalCity.jpg";
import Servicesimg5 from "./../../assets/images/portfolio/intercontinentalResidenceSuitesDubaiFestivalCity.jpg";
import Servicesimg6 from "./../../assets/images/portfolio/holidayInnDubaiFestivalCity.jpg";
import Servicesimg7 from "./../../assets/images/portfolio/vocoHotelDubai.jpg";
import Servicesimg8 from "./../../assets/images/portfolio/IntercontinentalHotelDubaiMarina.jpg";
import Servicesimg9 from "./../../assets/images/portfolio/holidayInnAbuDhabi.jpg";
import Servicesimg10 from "./../../assets/images/portfolio/babAlQasr.jpg";
import Servicesimg11 from "./../../assets/images/portfolio/intercontinentalFujairahResort.jpg";
import Servicesimg12 from "./../../assets/images/portfolio/intercontinentalMinaAlArabResort.jpg";
import ContactForm from "../../components/contact" 


function Portfolio() {

  
  return (
    <>
      <div className="portfolio_bg">
        <InnerBanner/>
      </div>
      <section className="section">
        <div className="container">
            <div className={Styles.serviceText}>
            <p>We placed our presence in the market by catering to
                the needs of our valuable clients</p>
            </div>
            <div className={Styles.services}>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg}/>
                  <h2>Crowne Plaza Dubai Marina</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg2}/>
                  <h2>Intercontinental Hotel Abu Dhabi</h2>
                  <div className={Styles.location}> 
                    Abu Dhabi - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg3}/>
                  <h2>Intercontinental Dubai Festival City</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg4}/>
                  <h2>Crowne Plaza Dubai Festival City</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg5}/>
                  <h2>Intercontinental Residence Suites Dubai Festival City</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg6}/>
                  <h2>Holiday Inn Dubai Festival City</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg7}/>
                  <h2>VOCO Hotel Dubai</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg8}/>
                  <h2>Intercontinental Hotel & Hotel Apartments Dubai Marina</h2>
                  <div className={Styles.location}>
                    Dubai - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg9}/>
                  <h2>Holiday Inn Abu Dhabi</h2>
                  <div className={Styles.location}>
                  Abu Dhabi - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg10}/>
                  <h2>Bab Al Qasr</h2>
                  <div className={Styles.location}>
                  Abu Dhabi - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg11}/>
                  <h2>Intercontinental Fujairah Resort</h2>
                  <div className={Styles.location}>
                  Fujariah - UAE
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg12}/>
                  <h2>Intercontinental RAK Mina Al Arab Resort & Spa</h2>
                  <div className={Styles.location}>
                    RAS AL KHAIMAH - UAE
                  </div>
              </div>
            </div>
        </div>
      </section>
      {/* <BeCreative/> */}
      <ContactForm/>

    </>
  );
}

export default Portfolio;