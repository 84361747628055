import { Link } from "react-router-dom";
import "./styles.scss";
import classnames from "classnames";




function ContactForm() { 

  return (
    <section className="section gray">
        <div className="container">
            <div className="contact">
            {/* <form 
              method='POST' 
              name='contactform' 
              className='contactForm'>

              <input 
                type='hidden'
                name='form-name'
                value='contactForm' />

              <input 
                type='text' 
                name='name' 
                placeholder='Full Name*' required/>

              <input 
                type='email' 
                name='email' 
                placeholder='Email*' required/>

            <input 
                type='number' 
                name='phone' 
                placeholder='Phone Number*' required /> 


              <button type='submit'>REQUEST A QUOTATION</button>
            </form> */}


            <form 
              method='POST' 
              name='contactform' 
              className='contactForm'>

              <input type='hidden' name='form-name' value='contactForm'  />

              <div className="form-filed">
                <input  type='text'  name='name'  placeholder='Full Name*' className="form-control" required />
              </div>
              <div className="formDual">
                <div className="form-filed">
                  <input  type='email'  name='email'  placeholder='Email*' className="form-control" required/>
                </div>
                <div className="form-filed">
                  <input  type='number'  name='phone'   placeholder='Phone Number*' className="form-control" required />
                </div>
              </div>
              <div className="formSubmitWrap">
                  <button type="submit" className="btn btn-submit w-100">REQUEST A QUOTATION</button>
              </div>

            </form>
            </div>
        </div>
    </section>
  );
}

export default ContactForm;
