import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "./../../components/innerbanner";
import Company from "../../components/company";
import OurProcess from "../../components/ourprocess";
import BeCreative from "./../../components/becreative";
import Testimonials from "../../components/testimonials";
import visionIcon from "../../assets/images/vision_icon.svg";
import missonIcon from "../../assets/images/mission.png";
import ValueIcon from "../../assets/images/value_icon.svg";
import OurClients from "../../components/clients";
import ContactForm from "../../components/contact";
import Experience from "../../components/experience";



function About() {

  
  return (
    <>
      <div className="about_bg">
        <InnerBanner/>
      </div>
      <div className="section">
        <div className="container-mini">
          <div className="flex-wrapper">
            <div className="w-40-p">
              <h2 className="head-style-02"></h2>
              <h1 className="head-style-01">
              ONE STOP SHOP FOR HOSPITALITY MANAGEMENT
              </h1>
            </div>
            <div className={classnames("w-60-p", Styles.bdrLeft)}>
            <p>
                Hostec is an inspiration founded by a team of Hospitality professionals in the year 2011,
                who have an experiance of more than 2 decades in various areas of Hospitality Industry.
                We provide a wide spectrum of solution to Owners, Developers as well as Operators.
                Our mission is to provide high quality Hospitality services and success of our client's
                objectives.
              </p>
              <p>
                Established in united Arab Emirates, we are in the process of expanding our
                operations to other parts of GCC and Asia.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Company/>
      <div className="section aim_bg">
        <div className="container-mini">
          <div className={Styles.vision_value}>
              <div className={Styles.vision_value_item}>
                <img src={visionIcon}/>
                <h3>vision</h3>
                <div className={Styles.overtext}>
                  Hostec is a Client-centric, 
                  flexible and versatile Organisation. 
                  We work together with our Clients
                  as One Team to achieve their 
                  Objectives. Our vision is to witness
                  the success of each of our
                  Clients by providing 
                  exceptional experiences.
                </div>
              </div>
              <div className={Styles.vision_value_item}>
                <img src={missonIcon}/>
                <h3>mission</h3> 
                <div className={Styles.overtext}>
                  HOSTEC’s mission is to achieve 
                  the pinnacle of Hospitality Services
                  in the region by creating Value
                  added experiences for our Clients, 
                  meaningful opportunities for 
                  our Team members and a 
                  Positive impact in the Community.
                </div>
                
              </div>
              <div className={Styles.vision_value_item}>
                <img src={ValueIcon}/>
                <h3>value</h3>
                <div className={Styles.overtext}>
                  The Core Values of HOSTEC are 
                  built on a strong foundation of 
                  Hospitable approach, taking 
                  complete Ownership of our
                  assignments, Sincere in
                  Client relationships, work as a
                  Team, Enterprising and 
                  Committed.
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* <BeCreative/> */}
      {/* <div className="section">
        <div className="container-mini">
          <h2 className="head-style-02 large mb-0">
            OUR <span>CLIENTS</span>
          </h2>
          <OurClients/>
        </div>
      </div> */}

<Experience/>

      <section className="section">
        <div className="container">
            <div className={Styles.serviceText}>
              <p>Our team of experts come With a welth of knowledge and experience who are capable of providing Advisory Services such as market Research Statistics, Feasibility Studies, Fodd& Beverage Consultancy, Pre-opening Consultancy, Development Adisory and Hotel Operator selection.</p>
            </div>
        </div>
      </section>

      {/* <BeCreative/> */}

      <ContactForm/>
    </>
  );
}

export default About;
