import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";
import classnames from "classnames";
import footerLogo from "../../assets/images/logo_footer.svg";
import fb_icon from "../../assets/images/fb_icon.png";
import in_icon from "../../assets/images/in_icon.png";
import insta_icon from "../../assets/images/insta_icon.png";
import msapLogo from "../../assets/images/mspa_logo.svg";
import frtImg1 from "../../assets/images/ftr_img-1.png";
import frtImg2 from "../../assets/images/ftr_img-2.png";
import frtImg3 from "../../assets/images/ftr_img-3.png";
import frtImg4 from "../../assets/images/ftr_img-4.png";
import frtImg5 from "../../assets/images/ftr_img-5.png";
import frtImg6 from "../../assets/images/ftr_img-6.png";

function Footer() {

  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <footer className={Styles.footer}>
        <div className="container-mini">
          <div className={Styles.footerLinks}>
            <div className="flex-wrapper">
                <div className="w-25-p">
                  <figure className={Styles.footerLogo}><img src={footerLogo}/></figure>
                  <div className={Styles.socialLinks}>
                    <a href="https://www.facebook.com/profile.php?id=100085677016767" target="_blank"><img src={fb_icon}/></a>
                    <a href="https://www.linkedin.com/company/www.hostec.com/about/?viewAsMember=true" target="_blank"><img src={in_icon}/></a>
                    <a href="https://www.instagram.com/hostec.me/" target="_blank"><img src={insta_icon}/></a>
                    
                    {/* <Link to="/"><img src={twt_icon}/></Link> */}
                    
                  </div>
                  <div className={Styles.links}>
                    
                      <p>
                        216A, Al Qiyadha Building, 
                        <br/>
                        Al Ittihad Square, Al Ittihad Road, Dubai
                        <br/>
                        +971 4 250 0697
                        <a href="mailto:info@hostec.com">info@hostec.com</a>
                      </p>
                    </div>
                </div>
                <div className="w-25-p">
                    <div className={Styles.links}>
                    <h3>NAVIGATE</h3>
                      <Link to="/home">HOME</Link>
                      <Link to="/whatwedo">WHAT WE DO</Link>
                      <Link to="/portfolio">PROTFOLIO</Link>
                      {/* <Link to="/testimonials">TESTIMONIALS</Link> */}
                      <a target='_blank' href="https://portal.mysterydelegates.com/auth/index.asp#login" >Sign up</a>
                    </div>
                </div>
                <div className="w-25-p">
                    <div className={Styles.links}>
                      <h3>WHAT WE DO</h3>
                      <Link to="/services">SERVICES</Link>              
                      <Link to="/trading">TRADING</Link>
                      <Link to="/advisory">ADVISORY</Link>
                      <img className={Styles.msapLogo} src={msapLogo}/>
                    </div>
                </div>
                <div className="w-25-p">
                  <div className={Styles.links}>
                    <h3>blogs</h3>
                    <div className={Styles.blogLinks}>
                      <a className={Styles.insta}>
                        <img src={frtImg1}/>
                      </a>
                      <a className={Styles.fb}>
                        <img src={frtImg2}/>
                      </a>
                      <a className={Styles.insta}>
                        <img src={frtImg3}/>
                      </a>
                      <a className={Styles.in}>
                        <img src={frtImg4}/>
                      </a>
                      <a className={Styles.in}>
                        <img src={frtImg5}/>
                      </a>
                      <a className={Styles.fb}>
                        <img src={frtImg6}/>
                      </a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className={Styles.copyright}>
            <p>Copyright © 2023 hostec. All Rights Reserved.</p>
            <p>Developed By <a href="https://metasoftit.com/">Metasoft It Solutions</a></p>
            <p><Link to="/">Terms of use</Link> |	<Link to="/">Privacy Environmental Policy</Link></p>
          </div>
        </div>
        <button className={Styles.scrollTop} onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}>Scroll Top</button>
      </footer>
    </>
  );
}

export default Footer;
