import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "./../../components/innerbanner";
import Servicesimg from "./../../assets/images/banners/service_04.webp";
import Servicesimg02 from "./../../assets/images/banners/service_05.webp";
import Servicesimg03 from "./../../assets/images/banners/service_06.webp";
import playIcon from "../../assets/images/play_icon.svg";
import BeCreative from "./../../components/becreative";
import Experience from "../../components/experience";
import ContactForm from "../../components/contact";


function WhatWeDo() {

  const handleClickScroll = () => {
    const element = document.getElementById('top');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView();
    }
  };

  return (
    <>
      <div className="whatwedo_bg" id="top">
        <InnerBanner/>
      </div>

      <section className="section">
        <div className="container">
            <div className={Styles.serviceText}>
              <p>Hospitality Services is the core business function of Hostec. we take pride in being
                able to provide all types of Hotel Management Services, right from Opertaion or Franchise selection to Asset Managment and Owner Representation </p>
            </div>
            <div className={Styles.services}>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg}/>
                  <h2>Services</h2>
                  <div className={Styles.action}>
                    <a href="/#/services"  onClick={handleClickScroll} className="btn btn-territory">explore now</a>
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg02}/>
                  <h2>Trading</h2>
                  <div className={Styles.action}>
                      <a href="/#/trading"  onClick={handleClickScroll} className="btn btn-territory">explore now</a>
                  </div>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg03}/>
                  <h2>Advisory</h2>
                  <div className={Styles.action}>
                    <a href="/#/advisory"  onClick={handleClickScroll} className="btn btn-territory">explore now</a>
                  </div>
              </div>
            </div>
        </div>
      </section>

      <Experience/>

      <section className="section">
        <div className="container">
            <div className={Styles.serviceText}>
              <p>Our team of experts come With a welth of knowledge and experience who are capable of providing Advisory Services such as market Research Statistics, Feasibility Studies, Fodd& Beverage Consultancy, Pre-opening Consultancy, Development Adisory and Hotel Operator selection.</p>
            </div>
        </div>
      </section>

      {/* <BeCreative/> */}

      <ContactForm/>

    </>
  );
}

export default WhatWeDo;
