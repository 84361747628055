import Styles from "./styles.module.scss";
import Banner from "../../components/banner";
import Testimonials from "../../components/testimonials";
import classnames from "classnames";
import { Link } from "react-router-dom";
import serviceImage01 from "../../assets/images/service_07.jpg";
import serviceImage02 from "../../assets/images/service_08.jpg";
import serviceImage03 from "../../assets/images/service_09.jpg";
// import OurProcess from "../../components/ourprocess";
// import BeCreative from "./../../components/becreative";
import OurClients from "../../components/clients";
import Company from "../../components/company";
import ContactForm from "../../components/contact" 


function Home() {


  const handleClickScroll = () => {
    const element = document.getElementById('top');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView();
    }
  };

  return (
    <section id="top">
      <Banner />

      <div className="section">
        <div className="container-mini">
          <div className="flex-wrapper">
            <div className="w-40-p">
              <h2 className="head-style-02"></h2>
              <h2 className="head-style-01">
             <h3 style={{textSizeAdjust:
              "20px"}}> ONE STOP SHOP FOR
              HOSPITALITY MANAGMENT</h3>
              </h2>
            </div>
            <div className="w-60-p">
              <p style={{alignItems:"20px"  }}>
              <b>Hostec</b> is an inspiration founded by a team of Hospitality professionals in the year 2011,
              who have an experience of more than 2 decades in various areas of Hospitality Industry.
              We provide a wide spectrum of solution to Owner, Developers as well as Operators.
              Our mission is to provide high quality Hospitality Services and ensure success of our client's
              objectives.
              </p>
                       <p>Established in United Arab Emirates, we are in the process of expanding our
              operations to other parts of GCC and Asia.</p>
              <a className="link-btn" href="/#/about"  onClick={handleClickScroll}>Explore for more &gt;&gt;</a>
            </div>
          </div>
        </div>
      </div>

      <div className="section dark">
        <div className="container">
          <div className="flex-wrapper g-0">
            <div className="w-50-p">
              <div className={Styles.serviceImage}>
                <img src={serviceImage01} />
              </div>
            </div>
            <div className="w-50-p">
              <div className={Styles.serviceDescription}>
                <h2 className="head-style-02 light" >
                  <span>SERVICES</span>
                </h2>
                <p>
                <span>We, at HOSTEC, are on a constantly evolving journey to create a One-stop shop for all our Clients in the Hospitality Industry. We believe in the fact that all divisions of the business need to communicate with each other seamlessly for a positive integration
towards achieving a common goal. 
                  </span>
                </p>
                <a className="link-btn" href="/#/services"  onClick={handleClickScroll}>Explore for more &gt;&gt;</a>
              </div>
            </div>
          </div>
          <div className="flex-wrapper reverse-mobile g-0">
            <div className="w-50-p">
              <div className={Styles.serviceDescription}>
                <h2 className="head-style-02 light">
                <span>TRADING</span>
                </h2>
                <p>
                With experience gained over years of working in the Hospitality Sector, HOSTEC team takes privilege in offering Hospitality products that can make your Property stand apart. From large Hotels, cruise lines, hospitals to simple B&Bs, we can help create a fresh new look and atmosphere by providing stylish and innovative Equipment and Supplies.
                </p>
                <a className="link-btn" href="/#/trading"  onClick={handleClickScroll}>Explore for more &gt;&gt;</a>
              </div>
            </div>
            <div className="w-50-p">
              <div className={Styles.serviceImage}>
                <img src={serviceImage02} />
              </div>
            </div>
          </div>
          <div className="flex-wrapper g-0">
            <div className="w-50-p">
              <div className={Styles.serviceImage}>
                <img src={serviceImage03} />
              </div>
            </div>
            <div className="w-50-p">
              <div className={Styles.serviceDescription}>
                <h2 className="head-style-02 light">
                <span>ADVISORY</span>
                </h2>
                <p>
                With experience gained over years of working in the Hospitality Sector, HOSTEC team takes privilege in offering Hospitality products that can make your Property stand apart. From large Hotels, cruise lines, hospitals to simple B&Bs, we can help create a fresh new look and atmosphere by providing stylish and innovative Equipment and Supplies.
                </p>
                <a className="link-btn" href="/#/advisory"  onClick={handleClickScroll}>Explore for more &gt;&gt;</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container-mini">
          <h2 className="head-style-02 large mb-0">
            OUR <span>CLIENTS</span>
          </h2>
         
          <OurClients/>
        </div>
      </div>

      <Company/>

      <ContactForm/>

    </section>
  );
}

export default Home;
