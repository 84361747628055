import React from 'react';
import InnerBanner from "./../../components/innerbanner";



function NotFound() {


    return (
        <>
            <div className="section error_page">
                <div className="container-mini text-center">
                    <h1 className="head-style-01">404</h1>
                    <h2>Sorry! Page Not Found!</h2>
                    <p>Oops! The page you are looking for does not exist.  Please return to the site’s <a href='/#/home'>homepage</a>.</p>
                </div>
            </div>

        </>
    );
}

export default NotFound;
