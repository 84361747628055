import { Link } from "react-router-dom";
import "./styles.scss";
import classnames from "classnames";

import expIcon from "../../assets/images/10_icon.png";


function Experience() { 

  return (
    <div className="succeessSection">
      <div className="container">
        <div className="successSection">
          <div className="years"><img src={expIcon}/></div>
          <p><span>YEARS OF SUCCESSFUL WORK</span>IN THE MARKET</p>
        </div>
      </div>
    </div>
  );
}

export default Experience;
