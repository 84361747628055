import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "../../components/innerbanner";
import Advisoryimg_01 from "../../assets/images/Advisory Group 1.png";
import Advisoryimg_02 from "../../assets/images/Advisory Group 2.png";
import Advisoryimg_03 from "../../assets/images/Advisory Group 3.png";
import Advisoryimg_04 from "../../assets/images/Advisory Group 4.png";
import Advisoryimg_05 from "../../assets/images/Advisory Group 5.png";
import Advisoryimg_06 from "../../assets/images/Advisory Group 6.png";
import playIcon from "../../assets/images/play_icon.svg";
import Servicesimg from "./../../assets/images/adv_01.png";
import Servicesimg02 from "./../../assets/images/adv_02.png";
import Servicesimg03 from "./../../assets/images/adv_03.png";
import ContactForm from "../../components/contact" 

const customStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: '0',
    bottom: '0',
  },
};

function Advisory() {


  const handleClickScrollTab = () => {
    const element = document.getElementById('tabScroll');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }



  return (
    <>
      <div className="advisory_bg">
        <InnerBanner />
      </div>

      <div className="section">
        <div className="container-mini text-center">
          <h2 className="head-style-02 center large">
            <span>ADVISORY</span>
          </h2>
          <p>The quality of information and facts is the key factor in any business to assess and choose the right actions at the right time. 
            Hostec will collate the most relevant and updated financial and non-financial data required for success. 
            We will ensure that our Clients can optimize their Business by effectively using our services.</p>
           {/*<div className={Styles.services}>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg}/>
                  <h2>Elegant </h2>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg02}/>
                  <h2>Efficient</h2>
              </div>
              <div className={Styles.serviceItem}>
                  <img src={Servicesimg03}/>
                  <h2>Exclusive</h2>
              </div>
  </div>*/}
        </div>
      </div>

      <div className="section dark pb-0">
        <div className="container-mini">
          <div className="text-center mb-50">
            <h2 className="head-style-02 large light center mb-0"><span>SERVICES</span></h2>
            <br></br>
            {/* <p><strong>what we are the benifits from us </strong></p> */}
          </div>
        </div>
        
        <Tabs>
        <div className="container-mini">
          <TabList className={Styles.servicesList}>
            <Tab onClick={handleClickScrollTab}>Pre-Opening Services</Tab>
            <Tab onClick={handleClickScrollTab}>Hotel Operator Selection</Tab>
            <Tab onClick={handleClickScrollTab}>Food & Beverage Consultancy</Tab>
            <Tab onClick={handleClickScrollTab}>Market Research & Feasibility Studies</Tab>
            <Tab onClick={handleClickScrollTab}>Turnaround Management</Tab>
            <Tab onClick={handleClickScrollTab}>Operating Manuals and P&P</Tab>
          </TabList>
          </div>
          <div className='whiteSection'  id='tabScroll'>
          <TabPanel>
            <div className="section popup">
            <div className="container-mini">
              <div className="flex-wrapper g-0">
                <div className="w-50-p">
                  <h2 className="head-style-02 large">
                    <span>Pre-opening Services...</span>
                  </h2>
                  <p>Whether operating under challenging market conditions or challenging socio-economic factors, key for a business to survive is flexibility and adaptability. Hostec has the capability to prepare specifically tailored Pre-opening and Operational services that are aligned to the Objectives of a Hotel Owner.  As part of our Pre-opening services, Hostec will work with the Architectural team, Designer team and the Project team to ensure that the pre-opening plans are transformed into an efficient operational frame work.  In addition to Hotel positioning and branding, we also assist in;</p>
                  <ul className="bullet_list_style">
                    <li>
                      preparing a detailed critical path
                    </li>
                    <li>
                      optimize the team hiring schedules
                    </li>
                    <li>
                      review, comment and approve pre-opening expense budget
                    </li>
                    <li>
                      review, comment and approve OS&E budget
                    </li>
                    <li>
                      provide assistance and approval on selection of Key Management team
                    </li>
                    <li>
                      review and approval of first year operational budget
                    </li>
                    <li>
                      setting up operational policies and procedures and much more…
                    </li>
                  </ul>
                </div>
                <div className="w-50-p">
                  <div className={Styles.servicePic}>
                    <img src={Advisoryimg_01} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </TabPanel>
          <TabPanel>
            <div className="section popup">
              <div className="container-mini">
                <div className="flex-wrapper g-0">
                  <div className="w-50-p">
                    <h2 className="head-style-02 large">
                      <span>Hotel Operator Selection</span>
                    </h2>
                    <p>Success of a Hotel business extensively depends upon the choice of the right Hotel Operator. A precarious mix of the right Operator at the right time increases the success ratio. A good brand may not always be the best Operator. HOSTEC can provide the expertise to identify this based on the type of Hotel, location and facilities available. We are experienced in preparing the three essential elements of an Operator Selection, which are legal, financial and Operator suitability. HOSTEC is famed for its capability to assess the suitability of an Operator based on setting up a winning Performance criteria, conducting legal as well as Financial Due Diligence of the Operator and negotiating the best Commercial terms. We ensure that the Operator not only ensures high profitability but also ensures maximizing asset value.</p>
                    
                  </div>
                  <div className="w-50-p">
                    <div className={Styles.servicePic}>
                      <img src={Advisoryimg_02} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="section popup">
              <div className="container-mini">
                <div className="flex-wrapper g-0">
                  <div className="w-50-p">
                    <h2 className="head-style-02 large">
                      <span>Food & Beverage Consultancies</span>
                    </h2>
                    <p>Success of a Hotel business extensively depends upon the choice of the right Hotel Operator. A precarious mix of the right Operator at the right time increases the success ratio. A good brand may not always be the best Operator. HOSTEC can provide the expertise to identify this based on the type of Hotel, location and facilities available. We are experienced in preparing the three essential elements of an Operator Selection, which are legal, financial and Operator suitability. HOSTEC is famed for its capability to assess the suitability of an Operator based on setting up a winning Performance criteria, conducting legal as well as Financial Due Diligence of the Operator and negotiating the best Commercial terms. We ensure that the Operator not only ensures high profitability but also ensures maximizing asset value.</p>
                  </div>
                  <div className="w-50-p">
                    <div className={Styles.servicePic}>
                      <img src={Advisoryimg_03} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="section popup">
              <div className="container-mini">
                <div className="flex-wrapper g-0">
                  <div className="w-50-p">
                    <h2 className="head-style-02 large">
                      <span>Market Research & Feasibility Studies</span>
                    </h2>
                    <p>Though most Owners and Financiers are capable of conducting a research on market and feasibility for their project, they may not be able to examine its viability with the same degree of independence and objectivity as a third party professional consultant. HOSTEC is capable of offering their expertise in conducting in-depth market studies and research. Our comprehensive reports and feasibility studies help Owners and Investors to assess the potential operating results of the proposed property or investment. Based on the scope required, each report will be tailor made to meet the client’ s requirements.</p>
                  </div>
                  <div className="w-50-p">
                    <div className={Styles.servicePic}>
                      <img src={Advisoryimg_04} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="section popup">
              <div className="container-mini">
                <div className="flex-wrapper g-0">
                  <div className="w-50-p">
                    <h2 className="head-style-02 large">
                      <span>Turnaround Management</span>
                    </h2>
                    <p>There are times when knowingly or unknowingly, a Hospitality Business undergoes Operational or Financial deterioration. At such times, the team of HOSTEC provides reassurance to the Client as well as their partners that their Company is in the process of carrying out proactive measures to enhance their value by modifying Operational and Financial strategies. First, actions are taken to plug the leaks to stabilize the business by overcoming under-performance. Then, changes are done to control costs and expenditures, improve cash flow and maximize operational performance.</p>
                  </div>
                  <div className="w-50-p">
                    <div className={Styles.servicePic}>
                      <img src={Advisoryimg_05} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="section popup">
              <div className="container-mini">
                <div className="flex-wrapper g-0">
                  <div className="w-50-p">
                    <h2 className="head-style-02 large">
                      <span>Operating Manuals and P&P</span>
                    </h2>
                    <p>Operation Manual & Policies and Procedures provide detailed information of principles and practices to control quality, manage performance and deliver consistent results. The HOSTEC team, with a huge amount of expertise gained over the years, create the foundation required to establish this suited to different types of Hospitality segments. We believe that this set of manuals and P&Ps should reflect the Mission, Vision and Values of an Organization.</p>
                  </div>
                  <div className="w-50-p">
                    <div className={Styles.servicePic}>
                      <img src={Advisoryimg_06} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          </div>
        </Tabs>
        
        <ContactForm/>
      </div>



      {/* <div className="section be_creative">
        <div className="container">
          <div className="be_creative_title">
            BE CREATIVE <img src={playIcon} /> WITH HASWELL
          </div>
        </div>
      </div> */}

    </>
  );
}

export default Advisory;
