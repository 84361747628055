import React from 'react';
import { BrowserRouter as Router, Routes, Route, HashRouter } from 'react-router-dom';   
import Header from './components/header'; 
import Footer from './components/footer';
import './App.css';
import './assets/scss/index.scss'; 
import Home from './pages/home';
import WhatWeDo from './pages/whatwedo';
import About from './pages/about';
import Portfolio from './pages/portfolio';
import TestimonialPage from './pages/testimonials';
import Services from './pages/services';
import Trading from './pages/trading';
import Advisory from './pages/advisory';
import NotFound from './pages/error';
import * as PATHS from './CONSTANTS'



function App() {
  
  return (
    <>
    <HashRouter>
        <Header/> 
        <Routes>
          <Route path={PATHS.HOME2} element={<Home />} />
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.WHATWEDO} element={<WhatWeDo />} />
          <Route path={PATHS.SERVICES} element={<Services/>} />
          <Route path={PATHS.TRADING} element={<Trading/>} />
          <Route path={PATHS.ADVISORY} element={<Advisory/>} />
          <Route path={PATHS.ABOUT} element={<About />} />
          <Route path={PATHS.PORTFOLIO} element={<Portfolio />} />
          <Route path={PATHS.TESTIMONIALS} element={<TestimonialPage />} />
          <Route path='*' element={<NotFound />}/> 
        </Routes>
        <Footer/>
    </HashRouter>
    
  </>
  );
}

export default App;
