import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "../../components/innerbanner";
import serviceImage04 from "../../assets/images/banners/service_04.webp";
import serviceImage01 from "../../assets/images/serviceImage01.png"; 
import planing_icon from "../../assets/images/plan_icon.svg";
import settings_icon from "../../assets/images/strategy_icon.svg";
import plan_icon_3 from "../../assets/images/acquistion_icon.svg";
import plan_icon_4 from "../../assets/images/execution_icon.svg";
import ContactForm from "../../components/contact" 
import { Link } from "react-router-dom";
import playIcon from "../../assets/images/play_icon.svg";



function Services() {
  return (
    <>
      <div className="services_bg">
        <InnerBanner/>
      </div>
    
      <div className="section">
        <div className="container-mini">
          <div className="flex-wrapper g-0">
            <div className="w-50-p">
              <div className={Styles.serviceImage}>
                <img src={serviceImage04} />
              </div>
            </div>
            <div className="w-60-p">
              <h2 className="head-style-02 large">
                <span>SERVICES</span>
              </h2>
              
              <p>We, at HOSTEC, are on a constantly evolving journey to create a One-stop shop for all our Clients in the Hospitality Industry.
                We believe in the fact that all divisions of the business need to communicate with each other seamlessly for a positive
                integration towards achieving a common goal.</p>
              <p>Our mission is to provide high quality Hospitality Services and ensure the success of our client’s objectives.  </p>
              <div className="flex-wrapper">
                <div className="w-50-p">
                  <div className={Styles.processitem}>
                    <figure>
                      <img src={planing_icon} />
                    </figure>
                    <div>
                      <h4>PLANING</h4>
                    </div>
                  </div>
                </div>
                <div className="w-50-p">
                  <div className={Styles.processitem}>
                    <figure>
                      <img src={settings_icon} />
                    </figure>
                    <div>
                      <h4>STRATEGY</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-wrapper">
                <div className="w-50-p">
                  <div className={Styles.processitem}>
                    <figure>
                      <img src={plan_icon_3} />
                    </figure>
                    <div>
                      <h4>ACQUISITION</h4>
                    </div>
                  </div>
                </div>
                <div className="w-50-p">
                  <div className={Styles.processitem}>
                    <figure>
                      <img src={plan_icon_4} />
                    </figure>
                    <div>
                      <h4>EXECUTION</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section dark">
        <div className="container-mini">
            <div className="text-center mb-50"> 
              <h2 className="head-style-02 large light center mb-0"><span>SERVICES</span></h2>
              <br></br>
              {/* <p><strong>what we are the benifits from us </strong></p> */}
              <p>Hospitality Services is the core business function of HOSTEC.
                We take pride in being able to provide all types of Hotel Management Services.</p>
            </div>
        </div>
        <div className="container-mini">
          <ul className={Styles.servicesList}>
            <li>Hotel Management Services</li>
            <li>Assest Management Services</li>
            <li>Mystery Delegates Services</li>
            <li>Integrity Audit Reviews</li>
            <li>Control Self Assessment Services</li>
            <li>Operational Risk Reviews</li>
            <li>Talent Development & Executive Search</li>
            <li>Operation Concepts & Brand Development</li>
            <li>Loyalty & Rewards Program Development</li>
          </ul>
        </div>
      </div>


      <div className="section">
        <div className="container-mini">
          <div className="flex-wrapper g-0">
            <div className="w-50-p">
              <h2 className="head-style-02 large">
                <span>HOTEL MANAGEMENT SERVICES...</span>
              </h2>
              <p>Hostec has a dedicated team with extensive knowledge and a wealth of experience in managing Hotel operations. We follow the philosophy that customers are professionals and deserve to be treated that way. </p>
               <p> The diverse expertise of our team allows us to provide professional service in all areas of Hotel Management to ensure your vision becomes a reality. </p>
            </div>
            <div className="w-50-p">
              <div className={Styles.servicePic}>
                <img src={serviceImage01} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
      {/* <div className="section be_creative">
        <div className="container">
            <div className="be_creative_title">
                BE CREATIVE <img src={playIcon}/> WITH HASWELL
            </div>
        </div>
      </div> */}

    </>
  );
  
}

export default Services;
