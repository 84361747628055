import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import menuIcon from "../../assets/images/list_icon.svg";
import classnames from "classnames";


function Header() {

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 500 ||
            document.documentElement.scrollTop > 500)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };


  return (
    <>
     <header className={isShrunk ? "headWrap static" : "headWrap"}>
      <section className={classnames(Styles.header, 'stickyHead')}>
        <div className="container">
          <div className={Styles.headerWrap}>
            <Link to="/home" className={Styles.logo} onClick={scrollToTop}><img src={logo} alt="logo" /></Link>
            <div className={Styles.navWrap}>
              <nav>
                <NavLink to="/home"  className={({ isActive }) => (isActive ? Styles.active : "")}  onClick={scrollToTop}>Home</NavLink>
                <div className={Styles.submenu}>
                  <NavLink to="/whatwedo" className={({ isActive }) => (isActive ? Styles.active : "")}  onClick={scrollToTop}>What We Do <span className={Styles.dwnArw}></span></NavLink>
                  <ul>
                    <li>
                      <NavLink to="/services" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Services</NavLink>
                    </li>
                    <li>
                      <NavLink to="/trading" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Trading</NavLink>
                    </li>
                    <li> 
                      <NavLink to="/advisory" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Advisory</NavLink>
                    </li>
                  </ul>
                </div>
                <NavLink to="/about" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>About us</NavLink>
                <NavLink to="/portfolio" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Portfolio</NavLink>
                {/* <NavLink to="/testimonials" className={({ isActive }) => (isActive ? Styles.active : "")}>Testimonials</NavLink> */}
                <a target='_blank' href="https://portal.mysterydelegates.com/auth/index.asp#login" >Sign up</a>
              </nav>
              <button onClick={toggleMenu}><img src={menuIcon} alt="menu" /></button>
            </div>
          </div>
        </div>
      </section>
      </header>
      {/* Popup menu */}
      <div className={openMenu ? Styles.popupMenuOpen : Styles.popupMenu}>
        <button className={Styles.close} onClick={toggleMenu}>
          close
        </button>
        <div className={Styles.popupWrap}>
          <div className={Styles.menu} onClick={toggleMenu}>
            <NavLink to="/home" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Home</NavLink>
            <NavLink to="/whatwedo" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>What We Do</NavLink>
            <NavLink to="/about" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>About us</NavLink>
            <NavLink to="/portfolio" className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Portfolio</NavLink>
            {/* <NavLink to="/testimonials" className={({ isActive }) => (isActive ? Styles.active : "")}>Testimonials</NavLink> */}
            <a target='_blank' href="https://portal.mysterydelegates.com/auth/index.asp#login" >Sign up</a>
          </div>
          <h3>Contact Us</h3>
          <div className={Styles.connectus}>
            <div className={Styles.connecttxt}>
              <p>
                216A, Al Qiyadha Building,
                <br />
                Al Ittihad Square, Al Ittihad Road, Dubai
                <br />
                +971 4 250 0697, +971 56 941 3280
                <a href="mailto:info@hostec.com">info@hostec.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Popup menu */}
    </>
  );
}

export default Header;
