import React from 'react';
import ReactDOM from 'react-dom';
import { HashScroll } from "react-hash-scroll";
import Styles from "./styles.module.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InnerBanner from "../../components/innerbanner";
import BeCreative from "./../../components/becreative";
import tradingimg1 from "../../assets/images/trading_pic.webp";
import furniture01 from "../../assets/images/Indoor_furniture1.png";
import furniture02 from "../../assets/images/Indoor_furniture2.png";
import furniture03 from "../../assets/images/Indoor_furniture3.png";
import furniture04 from "../../assets/images/outdoor_furniture1.png";
import furniture05 from "../../assets/images/outdoor_furniture2.png";
import furniture06 from "../../assets/images/outdoor_furniture3.png";
import Lighting01 from "../../assets/images/Indoor_Lighting1.png";
import Lighting02 from "../../assets/images/Indoor_Lighting2.png";
import Lighting03 from "../../assets/images/Indoor_Lighting3.png";
import Lighting04 from "../../assets/images/outdoor_Lighting1.png";
import Lighting05 from "../../assets/images/outdoor_Lighting2.png";
import Lighting06 from "../../assets/images/outdoor_Lighting3.png";
import InRoomAccesories01 from "../../assets/images/In-Room-Accessories1.png";
import InRoomAccesories02 from "../../assets/images/In-Room-Accessories2.png";
import InRoomAccesories03 from "../../assets/images/In-Room-Accessories3.png";
import GuestAmenities01 from "../../assets/images/Guest_Amenities1.png";
import GuestAmenities02 from "../../assets/images/Guest_Amenities2.png";
import GuestAmenities03 from "../../assets/images/Guest_Amenities3.png";
import HouseKeeping01 from "../../assets/images/HouseKeppingimage01.png";
import HouseKeeping02 from "../../assets/images/HouseKeppingimage02.png";
import HouseKeeping03 from "../../assets/images/HouseKeppingimage03.png";
import Bedandbathroomimage01 from "../../assets/images/Bedandbathroomimage01.png";
import Bedandbathroomimage02 from "../../assets/images/Bedandbathroomimage02.png";
import Bedandbathroomimage03 from "../../assets/images/Bedandbathroomimage03.png";
import FBTabletops01 from "../../assets/images/F&B Tabletops01.png";
import FBTabletops02 from "../../assets/images/F&B Tabletops02.png";
import FBTabletops03 from "../../assets/images/F&B Tabletops03.png";
import Buffet01 from "../../assets/images/Buffet01.png";
import Buffet02 from "../../assets/images/Buffet02.png";
import Buffet03 from "../../assets/images/Buffet03.png";
import spa01 from "../../assets/images/spa01.png";
import spa02 from "../../assets/images/spa02.png";
import spa03 from "../../assets/images/spa03.png";
import chemical01 from "../../assets/images/chemical01.png";
import chemical02 from "../../assets/images/chemical02.png";
import chemical03 from "../../assets/images/chemical03.png";
import playIcon from "../../assets/images/play_icon.svg";
import ContactForm from "../../components/contact" 



function Trading() {

  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickScrollTab = () => {
    const element = document.getElementById('tabScroll');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }



  return (
    <>
      <div className="trading_bg">
        <InnerBanner />
      </div>
      <section className="section small">
        <div className="container">
          <div className={Styles.tradingText}>
            <p className="mb-0">We help to create a fresh new look and atmosphere by providing stylish and innovative Equipment and Supplies.</p>
          </div>
        </div>
      </section>

      <div className="section dark">
        <div className="container-mini">
          <div className="flex-wrapper g-0">

            <div className="w-60-p">
              <h2 className="head-style-02 large light">
                <span>TRADING</span>
              </h2>
              <p>With experience gained over years of working in the Hospitality Sector, HOSTEC team takes privilege in offering Hospitality products that can
                make your Property stand apart. </p>
              <p>  From large Hotels, cruise lines, hospitals
                to simple B&Bs, we can help create a fresh new look and atmosphere by providing stylish and innovative Equipment and Supplies. From large Hotels, cruise lines, hospitals to simple B&Bs, we can help create a fresh new look and atmosphere by providing </p>

                {/* <Link to="/hash-scroll#contact" className="link-btn"></Link> */}
                <button className="link-btn mt-30" onClick={handleClickScroll}>Contact us for more &gt;&gt;</button>
            </div>
            <div className="w-50-p">
              <div className={Styles.serviceImage}>
                <img src={tradingimg1} />
              </div>
            </div>

          </div>
        </div>
      </div>

      <section className="section">
        <div className="container-mini">
       
        <Tabs>
            <TabList className={Styles.servicesList}>
              <Tab onClick={handleClickScrollTab}>Indoor Furniture</Tab>
              <Tab onClick={handleClickScrollTab}>Outdoor Furniture</Tab>
              <Tab onClick={handleClickScrollTab}>Indoor Lighting</Tab>
              <Tab onClick={handleClickScrollTab}>Outdoor Lighting</Tab>
              <Tab onClick={handleClickScrollTab}>In-Room Accessories</Tab>
              <Tab onClick={handleClickScrollTab}>Guest Amenities</Tab>
              <Tab onClick={handleClickScrollTab}>Housekeeping Equipment</Tab>
              <Tab onClick={handleClickScrollTab}>Bed & Bath Linen</Tab>
              <Tab onClick={handleClickScrollTab}>F&B Table Tops</Tab>
              <Tab onClick={handleClickScrollTab}>Buffet & Kitchen SOE</Tab>
              <Tab onClick={handleClickScrollTab}>Spa & Wellness Supplies</Tab>
              <Tab onClick={handleClickScrollTab}>Chemical & Cleaning Supplies</Tab>
            </TabList>
            <div id='tabScroll'>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">INDOOR <span>FURNITURE</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={furniture01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={furniture02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={furniture03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>Be it the lobby, restaurant or room, the first thing a guest observes is the furniture. The quality, finish and appeal of the furniture laid out can create the
                      best impression in the mind of your customer. The Bed base and frames, lobby hardware, and all other furniture should not only be heart-warming, but should
                      also spell operational efficiency.</p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">OUTDOOR <span>FURNITURE</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={furniture04} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={furniture05} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={furniture06} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>Filling up with furniture in your garden, pool or balconies is easy. However, weaving the functionality and design
                      to a perfect harmony is what HOSTEC does. The type of furniture sourced by us gives a timeless elegance and quality to the outdoor area. We can source out the best fit based on the space and layout at your premises.</p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">INDOOR <span>LIGHTING</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>Indoor lighting plays a very important role in determining the ambience of an area, whether it is the guest room, lobby, restaurant,
                      Spa or other recreational areas. It sets the mood and generates a positive atmosphere as soon as you step in. A good lighting solution
                      can not only add value to your property, but also reduce costs if chosen well.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">OUTDOOR <span>LIGHTING</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting04} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting05} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Lighting06} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>We take pride in being able to provide energy efficient outdoor lighting solutions, certified by third party organizations.
      We ensure that in addition to the efficiency of the outdoor lighting system, they are also able to keep pace in times of reducing emissions,
      energy saving and keen on environmental protection and sustainability.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">IN-ROOM <span>ACCESSORIES</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={InRoomAccesories01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={InRoomAccesories02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={InRoomAccesories03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>The Trading Division of HOSTEC can procure high quality In-room accessories like hair dryers, bins, minibars,
                    kettles, safes and other essentials which are bound to provide satisfaction to your customers. We intend to accessorize Hotel rooms
                    with products that are rated high in quality, in a way that your guest feels at Home.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">GUEST <span>AMENITIES</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={GuestAmenities01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={GuestAmenities02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={GuestAmenities03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>Among all Hotel Equipment and Supplies, Guest amenities are considered as the unsung heroes. The look, feel and quality of these amenities can
      either make you a star or quietly reject you. The experience of guests’ stay is rated on the basis of the quality of amenities provided as well, to a great extent.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">HOUSEKEEPING <span>EQUIPMENT'S</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={HouseKeeping01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={HouseKeeping02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={HouseKeeping03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                      Cleanliness of a Hotel can make or break a business. This will have a lasting impression on guests’ mind.
      HOSTEC team can procure good quality Housekeeping equipment, certified by third party organizations, that will enable the Hotel team to
      perform their task with ease and produce best results to the aspect of cleanliness of Guest rooms and Public areas.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">BED & BATH <span>LINEN</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={Bedandbathroomimage01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Bedandbathroomimage02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Bedandbathroomimage03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                    Feeling of Home away from Home is one of the expectations that every guest has when they stay at a Hotel.
      Once settled in their room after a good meal, soft and cozy linen, whether it is after bath accessories or sleep time accessories,
      is what one really looks forward to. There are very few other essentials that makes one feel at Home! We ensure you score on this.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">F&B <span>TABLE TOPS</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={FBTabletops01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={FBTabletops02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={FBTabletops03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                    What adorns a Dining Table, Jewels? Yes, in a way. Glassware, Flatware, Hollowware, cutleries, you name it. These are some of the
      jewels that create the magic in addition to the taste laid out in front of the guest. HOSTEC’s trading division has tied up with
      some of the best in this industry. We source them to match with your design and specifications.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">BUFFET & KITCHEN <span>SOE</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={Buffet01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Buffet02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={Buffet03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                    Setting up a Kitchen could be a substantial investment and quite mind-boggling. You not only want it to be functional, but also
      safe and effective. Rest assured, we can acquire top of the line equipment that will be the best-fit for the design of your Kitchen.
      HOSTEC also provides the latest buffet equipment sourced from some of the best manufacturers.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">SPA & WELLNESS <span>SUPPLIES</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={spa01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={spa02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={spa03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                    The concept of a Spa & Wellness center is based on improving the quality of life with positive benefits that will increase peace
      of mind, spirituality and physical well-being. It should stimulate all your five senses in harmony. Apart from the
      design and atmosphere, the next most important requirement is of equipment and supplies.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="section popup">
                <div className="container">
                  <h2 className="head-style-02 large center">CHEMICAL & CLEANING <span>SUPPLIES</span></h2>
                  <div className={Styles.trading}>
                    <div className={Styles.tradingItem}>
                      <img src={chemical01} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={chemical02} />
                    </div>
                    <div className={Styles.tradingItem}>
                      <img src={chemical03} />
                    </div>
                  </div>
                  <div className={Styles.tradingText}>
                    <p>
                    One of the divisions of HOSTEC provides chemical and cleaning supplies that are compliant with OH&S
      (occupational health & safety) directives and environment protection. The products are HACCP approved. On signing up,
      our team ensures that all the end users are trained appropriately to the usage of the supplies.
                    </p>
                  </div>
                </div>
              </section>
            </TabPanel>
            </div>
          </Tabs>
        </div>
      </section>
      <BeCreative/>


      <section className="section small">
        <div className="container">
          <div className={Styles.tradingText}>
            <p className="mb-0">We help to create a fresh new look and atmosphere by providing stylish and innovative Equipment and Supplies.</p>
          </div>
        </div>
      </section>
      <div id='contact'>
      <ContactForm/>
      </div>

    </>
  );
}

export default Trading;
