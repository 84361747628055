import { Link } from "react-router-dom";
import "./styles.scss";
import classnames from "classnames";

import expIcon from "../../assets/images/10_icon.png";


function Company() { 

  return (
    <div className="companySection">
      
      <div className="container">
          <div className="successSection">
            <div className="item">
              <h3>10+</h3>
              <p>year of experience</p>
            </div>
            <div className="item border">
              <h3>80%</h3>
              <p>Successful audits</p>
            </div>
            <div className="item">
              <h3>50+</h3>
              <p>Satisfied client</p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Company;
