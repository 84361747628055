import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerBanner from "./../../components/innerbanner";
import Testimonials from "../../components/testimonials";


function TestimonialPage() {

  
  return (
    <>
      <div className="testimonial_bg">
        <InnerBanner/>
      </div>
      <section className="section">
        <div className="container">
            <div className={Styles.serviceText}>
            <p>Your words and recognition always ignited a great inspiration to evolve our
                performance and stay relevant and unique</p>
            </div>
        </div>
      </section>
      <div className={classnames("section", Styles.testimonialBg)}>
        <div className="container">
            <h2 className="head-style-02 large light center">
              PEOPLES <span>TESTIMONIALS</span>
            </h2>
            <Testimonials/>
        </div>
      </div>

      
    </>
  );
}

export default TestimonialPage;
