import React from 'react';
import Slider from "react-slick";   
import Styles from "./styles.module.scss";
import classnames from "classnames";
import clientLogos01 from "../../assets/images/client_logos/logo01.png";
import clientLogos02 from "../../assets/images/client_logos/logo02.png";
import clientLogos03 from "../../assets/images/client_logos/logo03.png";
import clientLogos04 from "../../assets/images/client_logos/logo04.png";
import clientLogos05 from "../../assets/images/client_logos/logo05.png";
import clientLogos06 from "../../assets/images/client_logos/logo06.png";
import clientLogos07 from "../../assets/images/client_logos/logo07.png";
import clientLogos08 from "../../assets/images/client_logos/logo08.png";
import clientLogos09 from "../../assets/images/client_logos/logo09.png";
import clientLogos10 from "../../assets/images/client_logos/logo10.png";
import clientLogos11 from "../../assets/images/client_logos/logo11.png";
import clientLogos12 from "../../assets/images/client_logos/logo12.png";
import clientLogos13 from "../../assets/images/client_logos/logo_1.png";
import clientLogos14 from "../../assets/images/client_logos/logo_2.png";
import clientLogos15 from "../../assets/images/client_logos/logo_3.png";
import clientLogos16 from "../../assets/images/client_logos/logo_4.png";
import clientLogos17 from "../../assets/images/client_logos/logo_5.png";
import clientLogos18 from "../../assets/images/client_logos/logo_6.png";
import clientLogos19 from "../../assets/images/client_logos/logo_7.png";
import clientLogos20 from "../../assets/images/client_logos/logo_8.png";
import clientLogos21 from "../../assets/images/client_logos/logo_9.png";
import clientLogos22 from "../../assets/images/client_logos/logo_10.png";
import clientLogos23 from "../../assets/images/client_logos/logo_11.png";
import clientLogos24 from "../../assets/images/client_logos/logo_12.png";
import clientLogos25 from "../../assets/images/client_logos/logo_13.png";
import clientLogos26 from "../../assets/images/client_logos/logo_14.png";
import clientLogos27 from "../../assets/images/client_logos/logo_15.png";
import clientLogos28 from "../../assets/images/client_logos/logo_16.png";
import clientLogos29 from "../../assets/images/client_logos/logo_17.png";
import clientLogos30 from "../../assets/images/client_logos/logo_18.png";
import clientLogos31 from "../../assets/images/client_logos/logo_19.png";
import clientLogos32 from "../../assets/images/client_logos/logo_20.png";
import clientLogos33 from "../../assets/images/client_logos/logo_21.png";
import clientLogos34 from "../../assets/images/client_logos/logo_22.png";
import clientLogos35 from "../../assets/images/client_logos/logo_23.png";
import clientLogos36 from "../../assets/images/client_logos/logo_24.png";
import clientLogos37 from "../../assets/images/client_logos/logo_25.png";


function OurClients() {

  const settings = {
    dots: true,
    infinite: true,
    speed:1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true
  };

  return (
      <section className="clients-slider">
        <Slider {...settings}>
          <div className={Styles.sliderItem}>
            <h4><span>hotels &</span>  resorts</h4>
            <div className={Styles.clientLogos}>
              <figure>
                  <img src={clientLogos01}/>
              </figure>
              <figure>
              <img src={clientLogos07}/>
                  
              </figure>
              <figure>
              <img src={clientLogos10}/>
                  
              </figure>
              <figure>
              <img src={clientLogos09}/>
                  
              </figure>
              <figure>
              <img src={clientLogos12}/>
                
              </figure>
              <figure>
              <img src={clientLogos37}/>
                  
              </figure>
              <figure>
              <img src={clientLogos03}/>
            
              </figure>
              {/* <figure>
                  <img src={clientLogos08}/>
              </figure> */}
              <figure>
              <img src={clientLogos02}/>
              
              </figure>
              <figure>
              <img src={clientLogos06}/>
              
              </figure>
              <figure>
                  <img src={clientLogos11}/>
              </figure>
              <figure>
              <img src={clientLogos05}/>
              </figure>
              <figure>
              <img src={clientLogos04}/>
              </figure>
            </div>
          </div>
          <div className={Styles.sliderItem}>
            <h4><span>featured</span> Restaurant</h4>
            <div className={Styles.clientLogos}>
              <figure>
                  <img src={clientLogos15}/>
              </figure>
              <figure>
                  <img src={clientLogos23}/>
              </figure>
              <figure>
                  <img src={clientLogos27}/>
              </figure>
              <figure>
                  <img src={clientLogos25}/>
              </figure>
              <figure>
                  <img src={clientLogos29}/>
              </figure>
              <figure>
                  <img src={clientLogos30}/>
              </figure>
              <figure>
                  <img src={clientLogos26}/>
              </figure>
              <figure>
                  <img src={clientLogos22}/>
              </figure>
              <figure>
                  <img src={clientLogos24}/>
              </figure>
              <figure>
                  <img src={clientLogos34}/>
              </figure>
              <figure>
                  <img src={clientLogos16}/>
              </figure>
              <figure>
                  <img src={clientLogos18}/>
              </figure>

              
            </div>
          </div>
          <div className={Styles.sliderItem}>
            <h4><span>featured</span> Restaurant</h4>
            <div className={Styles.clientLogos}>
              <figure>
                  <img src={clientLogos13}/>
              </figure>
              <figure>
                  <img src={clientLogos14}/>
              </figure>
              <figure>
                  <img src={clientLogos17}/>
              </figure>
              <figure>
                  <img src={clientLogos19}/>
              </figure>
              <figure>
                  <img src={clientLogos20}/>
              </figure>
              <figure>
                  <img src={clientLogos21}/>
              </figure>
              <figure>
                  <img src={clientLogos28}/>
              </figure>
              <figure>
                  <img src={clientLogos31}/>
              </figure>
              <figure>
                  <img src={clientLogos32}/>
              </figure>
              <figure>
                  <img src={clientLogos33}/>
              </figure>
              <figure>
                  <img src={clientLogos35}/>
              </figure>
              <figure>
                  <img src={clientLogos36}/>
              </figure>
            </div>
          </div>
        </Slider>
      </section>
  );
}

export default OurClients;
